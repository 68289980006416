import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Destinations = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Destinations')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Destinations</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Destinations </li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
  
      <p>Discover limitless possibilities with {Application.ORG_DISPLAY_NAME}. Whether you're seeking adventure, relaxation, or exploration, we're here to guide you to your dream destinations. Explore the diverse range of locations we offer and let your travel journey take flight.</p>
      <strong>Enchanting Getaways:</strong>
      <p>Uncover the magic of distant lands and immerse yourself in cultures that will leave you enchanted. From the sun-soaked beaches of Bali to the vibrant markets of Marrakech, our enchanting getaways promise unforgettable experiences.</p>
      <strong>Urban Retreats:</strong>
      <p>If city lights and modern comforts beckon, our urban retreats have got you covered. Roam the lively streets of New York, indulge in the flavours of Tokyo, or admire the architectural marvels of Paris.</p>
      <strong>Nature's Serenity:</strong>
      <p>For those yearning for tranquillity in nature's embrace, our nature retreats offer serenity and breathtaking landscapes. Embark on a journey to the serene lakes of Switzerland or lose yourself amidst the lush rainforests of Costa Rica.</p>
      <strong>Thrilling Expeditions:</strong>
      <p>For the adventurers and thrill-seekers, our destinations offer heart-pounding experiences. Trek through the Inca Trail to Machu Picchu, dive deep into the Great Barrier Reef, or conquer the peaks of the Rocky Mountains.</p>
      <p>At {Application.ORG_DISPLAY_NAME}, we meticulously curate destinations that cater to every traveller's aspirations. Whether you're a lone wanderer, a couple seeking romance, a family longing for quality time, or a group of friends in pursuit of adventure, we have the perfect destination waiting for you. Let us be your guide to a world of remarkable experiences. Your journey starts here.</p>
     </div>
    </div>
    <section className="section">
     <div className="container">
      <h2 className="text-9 fw-500 text-center">Popular Destinations</h2>
      <p className="lead text-center mb-4">
       World's leading Flight Booking website.
      </p>
      <div className="row g-4 banner">
       <div className="col-md-8">
        <div className="item rounded h-100">
         <div className="caption text-center">
          <h2 className="text-7">Singapore</h2>
         </div>
         <div className="banner-mask"></div>
         <img className="img-fluid h-100" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/skyflightbooking/19.jpg`} alt="singapore" />
        </div>
       </div>
       <div className="col-md-4">
        <div className="row g-4">
         <div className="col-12">
          <div className="item rounded">
           <div className="caption text-center">
            <h2>Seattle</h2>
           </div>
           <div className="banner-mask"></div>
           <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/skyflightbooking/20.jpg`} alt="Seattle" />
          </div>
         </div>
         <div className="col-12 mt-lg-3">
          <div className="item rounded">
           <div className="caption text-center">
            <h2>London</h2>
           </div>
           <div className="banner-mask"></div>
           <img className="img-fluid" src={`${Application.STORAGE_URL_STATIC_ASSETS_PATH}/destination/skyflightbooking/21.jpg`} alt="London" />{' '}
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </section>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Destinations;