import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Terms and Conditions')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Terms and Conditions</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Terms and Conditions</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <div>
  
       <p>Introducing {Application.ORG_DISPLAY_NAME} Terms & Conditions, thoughtfully crafted to ensure complete transparency throughout your travel venture. By choosing {Application.ORG_DISPLAY_NAME} as your travel facilitator, you enter into an agreement to uphold these terms, fostering mutual understanding between us. These terms meticulously govern our service usage, providing essential insights into your rights and responsibilities as a traveller &ndash; encompassing reservation processes and payment guidelines. Prior to proceeding with any bookings or transactions, we strongly advise a thorough review of these terms.</p>
       <p><strong>Booking Reservations and Payments:</strong></p>
       <ul>
        <li>Each reservation made through {Application.ORG_DISPLAY_NAME} is subject to availability and the terms stipulated by the respective airlines.</li>
        <li>Payment is a prerequisite at the time of booking. All listed prices are expressed in US dollars, unless explicitly indicated otherwise.</li>
       </ul>
       <p><strong>Accuracy of Information:</strong></p>
       <p>While we exert ourselves to offer accurate and up-to-date information, {Application.ORG_DISPLAY_NAME} cannot guarantee the precision of flight schedules, fares, or any other details showcased on our platform. We recommend validating information with the respective airlines.</p>
       <p><strong>Refunds and Cancellations:</strong></p>
       <ul>
        <li>Policies concerning cancellations and refunds are dictated by the airline and fare category. It's your responsibility to comprehensively grasp these terms prior to finalizing a booking.</li>
        <li>{Application.ORG_DISPLAY_NAME} relinquishes any responsibility for fees or penalties levied by airlines in instances of cancellations or changes.</li>
       </ul>
       <p><strong>Limitation of Liability:</strong></p>
       <ul>
        <li>{Application.ORG_DISPLAY_NAME} holds no liability for losses, damages, delays, or expenses arising from actions or omissions by airlines, hotels, or other third-party providers.</li>
        <li>In no event shall {Application.ORG_DISPLAY_NAME} be held liable for any indirect, consequential, or punitive damages arising from your use of our services.</li>
       </ul>
       <p><strong>Changes to Terms and Conditions:</strong></p>
       <p>{Application.ORG_DISPLAY_NAME} retains the prerogative to modify or update these Terms & Conditions as deemed necessary. It is incumbent upon you to regularly review these terms.</p>
       <p>Through the use of our services and website, you acknowledge having read, understood, and agreed to these Terms & Conditions.</p>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Terms;