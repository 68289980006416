import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';
import Application from './config/application';

const CancelRefund = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Cancellation & Refund Policy</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Cancellation & Refund Policy</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
  
      <p>At {Application.ORG_DISPLAY_NAME}, we acknowledge that travel plans can take unexpected turns. Our commitment is to provide you with transparent and comprehensive guidelines regarding cancellations and refunds. We encourage you to thoroughly review the following policy before finalising any bookings.</p>
      <p><strong>Booking Cancellations:</strong></p>
      <p>Cancellation policies are subject to variations based on factors like the airline, fare type, and travel route. We highly recommend acquainting yourself with the specific cancellation conditions associated with your reservation. Cancellation procedures conducted through our website will adhere to the same stipulated policies.</p>
      <p><strong>Refund Eligibility:</strong></p>
      <p>Refund eligibility is contingent on the fare regulations established by the airline and the specific ticket type purchased. While certain tickets may not be eligible for refunds, others could qualify for partial or complete reimbursements, depending on the fare parameters.</p>
      <p><strong>Refund Process:</strong></p>
      <p>Once the airline approves a refund, {Application.ORG_DISPLAY_NAME}.com will process it in accordance with the airline's refund policy. Refunds will be directed back to the original payment method used during the booking.</p>
      <p><strong>Processing Time:</strong></p>
      <p>The time needed to process refunds varies and is subject to the airline's policies. In some instances, refund processing might extend over several weeks.</p>
      <p><strong>Fee Deductions:</strong></p>
      <p>Airlines may impose specific cancellation fees, change fees, or administrative costs, as outlined by their individual policies. {Application.ORG_DISPLAY_NAME}.com may also implement a service fee for managing cancellation and refund processes. This fee is designed to cover the administrative expenses tied to refund operations.</p>
      <p><strong>Changes To Policy:</strong></p>
      <p>{Application.ORG_DISPLAY_NAME}.com retains the right to revise or modify this Cancellation & Refund Policy whenever necessary. Any changes will be effective immediately upon being posted on our official website.</p>
      <p>By opting for {Application.ORG_DISPLAY_NAME} for your booking needs, you acknowledge that you have read, understood, and agreed to our Cancellation & Refund Policy. For any further questions or assistance, please do not hesitate to reach out to our dedicated customer support team.</p>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default CancelRefund;