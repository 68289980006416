import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('About Us')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>About Us</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">About Us</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <p>Welcome to {Application.ORG_DISPLAY_NAME}! We're thrilled to be your primary destination for travel solutions, seamlessly combining hassle-free experiences with budget-friendly options. As a dedicated independent consolidator, we excel at offering unparalleled flight deals and incredible offers across all major US airlines.</p>
      <p>Our core mission is to turn your travel dreams into reality. Through top-notch booking services, we ensure a journey that's smooth from departure to arrival. At {Application.ORG_DISPLAY_NAME}, your satisfaction and the creation of lasting travel memories are our top priorities. Our mission is clear: to shape your travel experience into a seamless, cost-effective, and truly enjoyable journey. With years of industry expertise, we've built strong partnerships with major US airlines, giving us the privilege to offer exclusive deals that can't be matched. Whether you're embarking on a solo adventure, planning a romantic getaway, or getting ready for a family vacation, {Application.ORG_DISPLAY_NAME} is here to meet all your needs.</p>
      <p>Our defining feature is our unwavering commitment to customer satisfaction. Our team of dedicated travel experts is available 24/7, ready to assist you at every step of your journey. From finding the perfect flight to ensuring a seamless booking process, we consistently go above and beyond to make your travel experience not just extraordinary, but truly exceptional.</p>
      <p>As you explore our user-friendly website, you'll discover endless possibilities. Immerse yourself in a diverse range of flight options, take advantage of irresistible offers, and leave the details to us, so you can focus on creating cherished memories.</p>
      <div className="row mt-4 mb-2">
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-thumbs-up"></i>
         </div>
         <h3>Best Deal Offer</h3>
         <p>
          Find our lowest deal to destinations worldwide, guaranteed
         </p>
        </div>
       </div>
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-paper-plane"></i>{' '}
         </div>
         <h3>Easy Booking</h3>
         <p>
          Search, select and save - the fastest way to book your trip
         </p>
        </div>
       </div>
       <div className="col-md-4">
        <div className="featured-box style-1">
         <div className="featured-box-icon text-primary">
          {' '}
          <i className="far fa-eye"></i>{' '}
         </div>
         <h3>24/7 Customer Care</h3>
         <p>
          Get award-winning service and special deals by calling{' '}
          {Application.SUPPORT_PHONE}
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default About;