import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
  <div id="main-wrapper">
   <Helmet title={composePageTitle('Privacy Policy')} />
   <Header />
   <section className="page-header page-header-dark bg-secondary">
    <div className="container">
     <div className="row align-items-center">
      <div className="col-md-8">
       <h1>Privacy Policy</h1>
      </div>
      <div className="col-md-4">
       <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
        <li>
         <RouterLink to={PageUrl.HOME}>Home</RouterLink>
        </li>
        <li className="active">Privacy Policy</li>
       </ul>
      </div>
     </div>
    </div>
   </section>
   <div id="content">
    <div className="container">
     <div className="bg-white shadow-md rounded p-4">
      <div>
       <p>At {Application.ORG_DISPLAY_NAME}, ensuring the privacy of your information is our foremost priority. Our Privacy Policy underscores our unwavering dedication to safeguarding your personal data. You can be confident that any information you provide to us will be treated with the utmost care and security. We only collect essential data that is necessary to facilitate your bookings and enhance your travel experience. Your trust holds immense value for us, and we are fully devoted to maintaining the confidentiality and security of your information.</p>
       <p><strong>Our Privacy Policy outlines how we gather, utilise, and protect your data when you interact with our website and services.</strong></p>
       <p><strong>Collection of Information:</strong></p>
       <p>When you navigate through our website or utilise our services, specific information is gathered. This may include details like your name, contact information, payment details, and travel preferences. We utilise this information to assist with bookings, personalise your journey, and provide important updates.</p>
       <p><strong>Utilisation of Data:</strong></p>
       <p>Your information is utilised to process bookings, provide customer support, and improve our services. Additionally, we may use your data for analytical purposes to enhance user experiences and optimise what we offer.</p>
       <p><strong>Sharing of Information:</strong></p>
       <p>To fulfil your bookings, we may share your data with third parties, which could include airlines and payment processors. Please be assured that we do not trade or lease your information to external parties for marketing purposes.</p>
       <p><strong>Security of Data:</strong></p>
       <p>We implement industry-standard security measures to safeguard your data against unauthorised access, alteration, or disclosure. However, it's important to note that complete security of online transmissions cannot be guaranteed, and absolute data security is beyond our control.</p>
       <p><strong>Links to Third Parties:</strong></p>
       <p>Our website may contain links that direct you to third-party sites. We assume no responsibility for the privacy practices or content of such external websites. We recommend reviewing their privacy policies before sharing any information.</p>
       <p>At {Application.ORG_DISPLAY_NAME}, we are resolutely dedicated to upholding your privacy while enhancing your travel experience.</p>
  
      </div>
     </div>
    </div>
   </div>
   <Footer />
  </div>
  );
  };
  
  export default Privacy;